<template>
    <div v-if="hasResource('asset_free_management')"  class="aops-free" >
      <div class="aop-searchForm search-form">
         <el-button
          v-if="hasResource('asset_free_add')"
          class="black-btn"
          @click="add">添加</el-button>
        <div class="aop-fix">
          <el-form :inline="true" :model="filter">
            <el-form-item label="内部ID">
              <el-input
                v-model="filter.origin_id"
                placeholder="输入内部ID"
                clearable
                @keyup.enter.native="addByEnterKey"/>
            </el-form-item>
            <el-form-item label="外部ID">
              <el-input
                v-model="filter.asset_uid"
                placeholder="输入外部ID"
                clearable
                @keyup.enter.native="addByEnterKey"/>
            </el-form-item>
             <el-form-item label="免费时间">
              <el-date-picker
                v-model="filter.date"
                style="width: 168px;"
                format="yyyy.MM.dd"
                type="daterange"
                range-separator="-"
                value-format="timestamp"
                start-placeholder="请选择"
                end-placeholder="请选择"/>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="filter.status">
                <el-option v-for="item in assetsStatus" :key="item.label" :label="item.label" :value="item.value"/>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="aop-textBtn">
            <span class="aop-clickButton" @click="inquire">查询</span>
            <span class="aop-clearButton aop-clickButton" @click="reset">清空</span>
          </div>
        </div>
      </div>
      <el-table
        v-loading="isTableLoading"
        :data="assetsData"
        class="data-table"
        header-cell-class-name="table-head"
        cell-class-name="table-cell">
        <el-table-column
            v-for="col in tableColumns"
            :key="col.label"
            :prop="col.prop"
            :label="col.label"
            :width="col.width"
            :min-width="col.minWidth"
            :align="col.align"
            :fixed="col.fixed">
            <template slot-scope="{row}">
                <div v-if="col.prop === 'preview'">
                  <img :src="row.preview + '?imageMogr2/thumbnail/x128/ignore-error/1/interlace/1'" class="aop-previewUrl" alt=""/>
                </div>
                <div v-else-if="col.prop==='start_time' || col.prop==='end_time'">
                    {{setTime(row[col.prop])}}
                </div>
                 <div v-else-if="col.prop==='status'">
                    {{`${row[col.prop]}` === '1'?'免费':'原价'}}
                </div>
                <div v-else-if="col.prop === 'operation'" class="icon-btn">
                    <el-button
                        size="mini"
                        type="text"
                        @click="handleShowDetail(row)" >
                        <i class="iconfont">&#xe662;</i>查看
                    </el-button>
                    <el-button
                        v-if="`${row.status}` === '1' && hasResource('asset_free_cancel')"
                        size="mini"
                        type="text"
                        @click="cancelFree(row)">
                        取消免费
                    </el-button>
                </div>
                <div v-else>{{row[col.prop] || '--'}}</div>
            </template>
        </el-table-column>
      </el-table>
      <div v-if="total != 0"  class="aop-pagination">
        <el-pagination
          :current-page.sync="currentPage"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
      <el-dialog
        top="12%"
        :close-on-click-modal="false"
        :visible.sync="visible"
        width="458px"
        class="add-dialog dialogTip">
        <div class="dialog-content">
          <div class="dialog-title">是否{{`取消免费【${currentAsset.name}】`}}</div>
        </div>
        <span slot="footer" class="dialog-footer pop-dialog">
          <el-button class="confirm-button" :loading="btnLoading"  @click="submit()">确认</el-button>
          <el-button class="cancel-button" @click="visible = false">取 消</el-button>
        </span>
      </el-dialog>
    </div>
</template>
<script>
import { formatDate } from 'ecoplants-lib';
import api from '../../../api/assets';
import { assetsStatus, filter, tableColumns } from './data';

export default {
    name: 'FreeAssets',
    /**
    * 基础数据&定义数据
     @return{*}
    */
    data() {
        return {
            tableColumns,
            filter,
            assetsStatus,
            total: 0,
            pageSize: 10,
            currentPage: 1,
            isTableLoading: true,
            btnLoading: false,
            assetsData: [],
            visible: false,
            currentAsset: {}
        };
    },
    /**
    * 创建时
    */
    created() {
        this.reset();
        this.getAssetsList();
    },
    methods: {
        /**
        * 查询
         * @param {*} e 值
        */
        addByEnterKey(e) {
            if (e.keyCode == 13) {
                this.currentPage = 1;
                this.getAssetsList();
            }
        },
        /**
         * 查询
         */
        inquire() {
            this.currentPage = 1;
            this.getAssetsList();
        },
        /**
        * 查询
         * @param {*} val 值
        */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getAssetsList();
        },
        /**
        * 查询
         * @param {*} val 值
        */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getAssetsList();
        },
        /**
        * 重置 todo
        */
        reset() {
            this.currentPage = 1;
            this.filter = {
                origin_id: '',
                asset_uid: '',
                date: '',
                status: 0
            };
        },
        /** 添加 */
        add() {
            this.$router.push('/free-create');
        },
        /**
        * 添加
         * @param {*} val 值
        @returns{*}
        */
        setTime(val) {
            return formatDate(val * 1000, 'yyyy-MM-dd hh:mm:ss') || '--';
        },
        /**
        * 列表数据
        */
        getAssetsList() {
            this.isTableLoading = true;
            let time;
            if (this.filter.date && this.filter.date.length > 0) {
                time = this.filter.date;
                time[1] = new Date(`${formatDate(time[1], 'yyyy-MM-dd')} 23:59:59`).getTime();
            }
            const data = {
                limit: this.pageSize,
                page: this.currentPage,
                filter: {
                    origin_id: this.filter.origin_id,
                    asset_uid: this.filter.asset_uid,
                    status: this.filter.status,
                    start_time: time && time[0] / 1000,
                    end_time: time && time[1] / 1000
                }
            };
            try {
                api.assetFree(data).then((res) => {
                    if (res.data.code === 10200) {
                        this.assetsData = res.data.data.items;
                        this.total = res.data.data.total;
                        this.currentPage = res.data.data.page;
                    }
                    this.isTableLoading = false;
                });
            } catch (e) {
                this.isTableLoading = false;
            }
        },
        /**
        * 查看详情
        * @param {*} val 值
        */
        handleShowDetail(val) {
            // todo 待接口确定查询 id, 用什么id查询
            this.$router.push(`/free-detail?id=${val.asset_uid}`);
        },
        /**
        * 取消免费
        * @param {*} val 值
        */
        cancelFree(val) {
            this.visible = true;
            this.currentAsset = val;
        },
        /**
         * 确认取消
         */
        submit() {
            const data = {
                id: this.currentAsset.id
            };
            this.btnLoading = true;
            try {
                api.cancelFree(data).then((res) => {
                    if (res.data.code === 10200) {
                        setTimeout(() => {
                            this.inquire();
                            this.$$success('操作成功');
                            this.visible = false;
                            this.btnLoading = false;
                        }, 1500);
                    } else {
                        this.btnLoading = false;
                        this.$$error(res.data.message);
                    }
                });
            } catch (e) {
                this.btnLoading = false;
            }
        }
    }
};
</script>

<style lang="less">
    @import "./index.less";
</style>
