export const tableColumns = [
    { prop: 'preview', label: '缩略图', width: 200, align: 'left' },
    { prop: 'origin_id', label: '内部ID', width: 240, align: 'left' },
    { prop: 'asset_uid', label: '外部ID', width: 240, align: 'left' },
    { prop: 'chinese_name', label: '中文名称', width: 240, align: 'left' },
    { prop: 'name', label: '英文名称', width: 240, align: 'left' },
    // { prop: 'cost', label: '原价', width: 200, align: 'left' },
    { prop: 'status', label: '状态', width: 200, align: 'left' },
    { prop: 'start_time', label: '开启免费时间', width: 280, align: 'left' },
    { prop: 'end_time', label: '结束免费时间', width: 280, align: 'left' },
    // { prop: 'buy_count', label: '购买次数', width: 180, align: 'left' },
    { prop: 'download_count', label: '下载次数', minWidth: 180, align: 'left' },
    { prop: 'operation', label: '操作', width: 280, align: 'left', fixed: 'right' }
];

export const filter = {
    origin_id: '',
    asset_uid: '',
    date: [],
    status: 0
};

export const assetsStatus = [
    {
        value: 0,
        label: '全部'
    },
    {
        value: 1,
        label: '免费'
    },
    {
        value: 2,
        label: '原价'
    }
];
export const assetInfo = [
    {
        id: 1,
        children: [{
            label: '内部ID',
            key: 'origin_id'
        }, {
            label: '资产类型',
            key: 'type'
        }, {
            label: '英文名称',
            key: 'name'
        }, {
            label: '英文标签',
            key: 'tags',
            type: 'tooltip'
        }, {
            label: '3S效果',
            key: 'is_sss'
        }, {
            label: '全屏放大',
            key: 'is_full_screen'
        }, {
            label: '资产分类',
            key: 'categories',
            type: 'tooltip'
        }, {
            label: '是否封闭',
            key: 'model_state'
        }]
    }, {
        id: 2,
        children: [{
            label: '外部ID',
            key: 'asset_uid'
        }, {
            label: '资产大小',
            key: 'resource_total_size'
        }, {
            label: '中文名称',
            key: 'chinese_name'
        }, {
            label: '中文标签',
            key: 'chinese_tags',
            type: 'tooltip'
        }, {
            label: '关键词',
            key: 'searchTags'
        }, {
            label: '多模型资产',
            key: 'is_grouped'
        }, {
            label: '颜色属性',
            key: 'color'
        }, {
            label: '长宽高',
            key: 'size'
        }]
    }
];

export const marketInfo = [
    {
        id: 1,
        children: [
            // {
        //     label: '发布市场',
        //     key: 'origin_id'
        // },
            {
                label: '发布时间',
                key: 'published_at'
            }]
    }, {
        id: 2,
        children: [{
            label: '下载价格',
            key: 'cost'
        }]
    }
];
// todo ,字段名匹配
export const downloadInfo = [
    {
        id: 1,
        children: [{
            label: 'Material Preset',
            key: 'material_preset'
        },
        {
            label: 'Albedo',
            key: 'albedo'
        },
        {
            label: 'Roughness',
            key: 'roughness'
        },
        {
            label: 'Displacement',
            key: 'displacement'
        },
        {
            label: 'Specular',
            key: 'specular'
        },
        {
            label: 'Cavity',
            key: 'cavity'
        }]
    }, {
        id: 2,
        children: [{
            label: 'Normal',
            key: 'normal'
        },
        {
            label: 'Metallic',
            key: 'metallic'
        },
        {
            label: 'AO',
            key: 'ao'
        },
        {
            label: 'Gloss',
            key: 'gloss'
        },
        {
            label: 'Scatter',
            key: 'scatter'
        },
        {
            label: 'Fuzz',
            key: 'fuzz'
        }]
    }
];
